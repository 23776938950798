require('./bootstrap');
require('./tooltip');
require('./dropDocument');
require('./documents');
require('./modal');
require('./search.js');
require('./sw.js');         //create service worker for web app
require('./helper.js');

// Chart JS
import Chart from 'chart.js/auto';
window.Chart = Chart;