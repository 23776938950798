if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceworker.js', {
        scope: '.'
    }).then(function(registration) {
        // Registration was successful  
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
        // registration failed :(  
        console.log('ServiceWorker registration failed: ', err);
    });  

    //add offline notification function via css class and modal
    window.addEventListener("load", () => {
        function handleNetworkChange(event) {
            if (navigator.onLine) {
                document.body.classList.remove("offline");
                if(offlineModal) {
                    offlineModal.hide();
                }
            } else {
                document.body.classList.add("offline");
                if(offlineModal) {
                    offlineModal.show();
                }
            }
        }
        window.addEventListener("online", handleNetworkChange);
        window.addEventListener("offline", handleNetworkChange);
    });
 }