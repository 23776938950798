simpleSearch = function (searchstring, simpleSearchResult) {
    console.log(searchstring);
    if (searchstring) {
        fetch("/search", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content,
            },
            body: JSON.stringify({ searchstring : searchstring}),
        })
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                simpleSearchResult.innerHTML = text;
                //make items clickable
                document.querySelectorAll('.searchresult-item').forEach( function(e) { e.addEventListener('click', () => location.href = e.dataset.link) } );
            });
    } else {
        simpleSearchResult.innerHTML = "";
    }

    //store search value
    localStorage.setItem("simpleSearch", searchstring);
};

var searchTimeout = 0;
initSearchFunctions = function () {
    if ((searchInput = document.getElementById("inputSimpleSearch"))) {
        let simpleSearchResult = document.getElementById("simpleSearchResult");

        //event for typing
        ["keypress", "keyup", "click"].forEach((eType) => {
            searchInput.addEventListener(eType, () => { clearTimeout(searchTimeout); searchTimeout = setTimeout(() => simpleSearch(searchInput.value, simpleSearchResult), 500)});
        });

        //event for esc to abort search
        searchInput.addEventListener("keydown", (e) => {
            if (e.key == "Escape") {
                clearTimeout(searchTimeout);
                simpleSearchResult.innerHTML = "";
            }
        });

        //focus
        //searchInput.focus();
        //restore last search value
        searchInput.value = localStorage.getItem("simpleSearch");
    }
};

initSearchFunctions();