const deleteModal = document.getElementById('deleteModal') ? bootstrap.Modal.getOrCreateInstance(document.getElementById('deleteModal')) : null;
const offlineModal = document.getElementById('offlineModal') ? bootstrap.Modal.getOrCreateInstance(document.getElementById('offlineModal')) : null;

initModalButtons = function() {
    document.querySelectorAll("[data-button-type='delete']").forEach(
        function(e) {
            e.onclick = function(Event) {
                Event.stopPropagation();
                //set modal data
                if(deleteModal) {
                    //set the item name to be displayed as info which item should be deleted
                    let itemName = deleteModal._element.querySelectorAll('div.modVar')[0];
                    if(e.getAttribute("data-item-name")) {
                        itemName.textContent = e.getAttribute("data-item-name");
                        itemName.style.display = 'inherit';
                    } else {
                        itemName.style.display = 'none';
                    }
                    //set the action of the form to perform if the user confirms the dialog
                    deleteModal._element.querySelectorAll('form')[0].action = e.getAttribute("data-form-action") ?? '';
                    deleteModal.show();
                } else {
                    alert('ERROR: [ deleteModal ] not found!');
                }
                return false;
            };
        }
    );
};

document.addEventListener("DOMContentLoaded", () => {
    initModalButtons();
});