loadDocuments4CategoryByPagination = function(url, category, urlCurrent) {
    fetch(url)
    .then(response => response.json())
    .then(function(data) {
        addDocument(category, data);
        initModalButtons();
        initTooltips();
        window.history.pushState({"html":document.getElementById('cat_'+category).innerHTML,"pageTitle":''},"", urlCurrent + '?cat=' + category);
    })
    .catch(error => console.log(error));   
}

addDocument = function(category, data) {
    let target = document.getElementById('cat_'+category);
    target.innerHTML = '';
    
    let listGroup = document.createElement('div');
    listGroup.classList.add("list-group", "shadow-sm");
    for(let item of data.data) {
        var listItem = document.createElement('div');
        listItem.setAttribute("role", "button");
        listItem.addEventListener('click', function() {location.href = item.url} );
        listItem.classList.add('list-group-item', 'list-group-item-action', 'item-document');
        listItem.innerHTML = `
                            <div class="row">
                                <div class="col-lg-8 col-md-7 pt-1">
                                    <i class="fa fa-${item.icon} fa-fw me-1" aria-hidden="true"></i><a href="${item.url}">${item.name}</a>
                                </div>
                                <div class="col-lg-4 col-md-5 text-end">
                                    <div class="small text-secondary d-inline-block pt-1 pe-1">${new Date(item.updated_at).toLocaleDateString()}</div>
                                    |
                                    <a href="${item.urlDirect}" target="_blank" class="btn btn-outline-secondary btn-sm ms-1 me-1" data-bs-toggle="tooltip" title="${item.urlDirectTooltip}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
                                    <a href="${item.urlDownload}" target="_blank" class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="tooltip" title="${item.urlDownloadTooltip}"><i class="fa fa-download" aria-hidden="true"></i></a>
                                    |
                                    <button class="btn btn-outline-danger btn-sm ms-1" data-button-type="delete" data-item-name="${item.name}" data-form-action="www.google.de">
                                        <i class="fa fa-trash-o fa-fw" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>`;
        //stop outer link
        listItem.querySelectorAll('a.btn').forEach(function(e) {
            e.addEventListener('click', function(e) { e.stopPropagation() });           
        });
        listGroup.appendChild(listItem);
    }
    if(!data.data.length) {
        listGroup.innerHTML = '<div class="list-group-item text-secondary fst-italic bg-light">'+data.noItemsText+'</em>';
    }

    target.appendChild(listGroup);

    if(data.links.length > 3) {
        var pagination = document.createElement('div');
        let paginationTemplate = `
        <nav aria-label="document pagination">
            <ul class="pagination pagination-sm justify-content-center mt-2">
                ${data.links.map(link => `<li class="page-item ${!link.url ? 'disabled' : '' } ${link.active ? 'active' : ''}">
                    <a class="page-link" href="#" onclick="${link.url ? `loadDocuments4CategorByPagination('${link.url}', ${category});` : ''} return false;">
                        ${link.label}
                    </a>
                </li>`).join("")}
            </ul>
        </nav>
        `;
        pagination.innerHTML = paginationTemplate;
        target.appendChild(pagination);
    }
    
}