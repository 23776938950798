//drag n drop document upload can be used at different locations
function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
}

document.addEventListener("DOMContentLoaded", () => {
    let fileUpload = document.getElementById('fileUpload');
    if(fileUpload) {
        fileUpload.addEventListener('change', function() {
            document.getElementById('inputName').value = (this.value.split('\\').pop());

            if(document.getElementById('fileUploadContainer'))
                document.getElementById('fileUploadContainer').classList.remove('alert-danger');

            if(document.getElementById('fileUploadError'))
                document.getElementById('fileUploadError').style.display = 'none';
        });
    }

    let fileUploadButton = document.getElementById('btnFileUpload');
    if(fileUploadButton) fileUploadButton.addEventListener('click', function(e) { preventDefaults(e); document.getElementById('fileUpload').click(); return false; });

    let dropAreas = document.getElementsByClassName('document-drop-area');
    if(dropAreas) {
        for(let dropArea of dropAreas) {

            //prevent default behavior
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, preventDefaults, false)
            })

            dropArea.addEventListener('dragenter', function() { this.classList.add("dragOver"); }, false)
            dropArea.addEventListener('dragover', function() { this.classList.add("dragOver"); }, false)
            dropArea.addEventListener('dragleave', function() { this.classList.remove("dragOver"); }, false)

            dropArea.addEventListener('drop', function(evt) {
                let fileUpload = document.getElementById('fileUpload');
                console.log(fileUpload);
                this.classList.remove("dragOver");
                fileUpload.files = evt.dataTransfer.files;
                fileUpload.change();
            }, false);
        };
        /*
        dropArea.addEventListener('dragenter', handlerFunction, false)
        dropArea.addEventListener('dragleave', handlerFunction, false)
        dropArea.addEventListener('dragover', handlerFunction, false)
        dropArea.addEventListener('drop', handlerFunction, false)
        */
    }
});
